import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { preToCodeBlock } from 'mdx-utils';
import Code from '../Code';

// components is its own object outside of render so that the references to
// components are stable
const componentsEl = {
  pre: function pre(preProps) {
    const props = preToCodeBlock(preProps);
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />;
    }

    // it's possible to have a pre without a code in it
    return <pre {...preProps} />;
  },
  wrapper: function wrapper({ children }) {
    return <>{children}</>;
  },
};

export const wrapRootElement = ({ children }) => (
  <MDXProvider components={componentsEl}>{children}</MDXProvider>
);
