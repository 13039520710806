import { createGlobalStyle } from 'styled-components';
const fontFamily = '"Wotfard", Futura, -apple-system, sans-serif';

export const breakpoints = {
  DESKTOP: 992,
  TABLET: 768,
  PHONE: 376,
};

export const theme = {
  background: '#e6e3d9',
  backgroundBlured: '#e6e3d9E6',
  bgSvg: 'e6e3d9',
  bgSvgLine: '0e141b08',

  fontFamily,
  primary: '#e60067',
  secondary: '#4333ff',
  tertiary: '#2c0b8e',
  syntaxBg: '#f5f6f9',
  syntaxHighlight: '#e9ebf2',
  text: '#0a0c10',
  icons: '#414c55',
  // Home
  homeBgLight: '#d1e8f7',
  homeBgDark: '#adddfc',
  red: '#f82c2c',
  // Gray
  gray100: 'hsl(225deg, 25%, 95%)',
  gray200: 'hsl(225deg, 16%, 90%)',
  gray300: 'hsl(225deg, 8%, 80%)',
  gray400: 'hsl(225deg, 8%, 70%)',
  gray500: 'hsl(225deg, 7%, 60%)',
  gray600: 'hsl(225deg, 15%, 50%)',
  gray700: 'hsl(225deg, 12%, 40%)',
  gray900: 'hsl(225deg, 25%, 20%)',
  gray1000: 'hsl(225deg, 15%, 15%)',
  breakpoints,

  textBlock: {
    success: '#e0f8e5',
    info: '#dfebf6',
    warning: '#f8f1e4',
    danger: '#f8e4e4',
    // Text block border
  },
  textBlockBorder: {
    success: '#46c763',
    info: '#4333ff',
    warning: '#ffc107',
    danger: '#ee5252',
  },

  code0: '#0b0b0b',
  code1: '#6520ff',
  code2: '#4078f2',
  code3: '#44464d',
  code4: '#50a14f',
  code5: '#222627',
  code6: '#222627',
  code7: '#e56357',
};

export const darkTheme = {
  background: '#0e141b',
  backgroundBlured: '#0e141bE6',
  bgSvg: '0e141b',
  bgSvgLine: 'eeeeee10',
  fontFamily,
  primary: '#e60067',
  secondary: '#5773ff',
  tertiary: '#ffe100',
  syntaxBg: '#151f28',
  syntaxHighlight: '#212e3c',
  text: '#ffffff',
  icons: '#b6b9bb',
  // Home
  homeBgLight: '#1c272f',
  homeBgDark: '#151d24',
  red: '#f82c2c',
  // Gray
  gray100: 'hsl(210deg, 15%, 20%)',
  gray200: 'hsl(210deg, 15%, 25%)',
  gray300: 'hsl(210deg, 10%, 40%)',
  gray400: 'hsl(210deg, 9%, 45%)',
  gray500: 'hsl(210deg, 8%, 50%)',
  gray600: 'hsl(210deg, 12%, 55%)',
  gray700: 'hsl(210deg, 14%, 66%)',
  gray900: 'hsl(210deg, 25%, 88%)',
  gray1000: 'hsl(210deg, 25%, 96%)',
  breakpoints,

  // Text Block
  textBlock: {
    success: '#3f774b',
    info: '#182635',
    warning: '#796733',
    danger: '#693434',
  },
  textBlockBorder: {
    success: '#46c763',
    info: '#5773ff',
    warning: '#ffc107',
    danger: '#ee5252',
  },

  code0: '#d2d2d2',
  code1: '#5299d5',
  code2: '#d7d8a4',
  code3: '#dc6cd8',
  code4: '#47c3aa',
  code5: '#96d5f8',
  code6: '#c68b71',
  code7: '#5095d0',
};

export const timeout = 250;

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: ${fontFamily};
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  *, ::before, ::after {
    box-sizing: border-box;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: ${fontFamily};
    line-height: 1;
    font-size: 1.6rem;
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.background};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
    transition: color 350ms ease 0s, background-color 350ms ease 0s;
  }
  a {
    text-decoration: none;
    font-weight: 300;
  }

  p {
    font-weight: 300;
  }

  h1, h2, h3, h4, h5, h6 {
    transition: color 350ms ease 0s;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .layout {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
    @media (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  @media (orientation: landscape) {
    ::-webkit-scrollbar {
        width: 12px;
        background-color: ${props => props.theme.gray100};
    }
  }

  @media (orientation: landscape) {
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: ${props => props.theme.gray700};
        border: 2px solid ${props => props.theme.gray100};
    }
  }
  @media (orientation: landscape) {
    ::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: transparent;
    }
  }

`;
