import styled from 'styled-components';

export const CodeWrapperCss = styled.div`
  margin: 0 0 80px;
  .gatsby-highlight-code-line {
    background-color: ${props => props.theme.syntaxHighlight};
    display: block;
    margin-left: -2px;
    padding: 0 10px;
    border-left: 2px solid #df6b6b;
  }

  /**
 * Add back the container background-color, border-radius, padding, margin
 * and overflow that we removed from <pre>.
 */
  .gatsby-highlight {
    position: relative;
  }

  // Styling for react-live code
  .live-highlight {
    position: relative;
    margin: 0.8rem 0;
    padding: 0.6rem;
    overflow: auto;
    border-width: 1px;
    border-style: solid;

    & pre,
    & textarea {
      font-size: 0.8rem !important;
      line-height: 1.5 !important;
    }

    & pre {
      background-color: transparent !important;
      margin: 0;
      padding: 0;
      overflow: initial;
    }

    & textarea {
      line-height: 6px;
      &:focus {
        outline: none;
      }
    }

    &:before {
      content: 'Live';
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.4rem 0.6rem;
      font-size: 0.875rem;

      @media (max-width: 500px) {
        font-size: 0.8rem;
      }
    }
  }

  // Live code preview styling
  .live-preview {
    position: relative;
    padding: 0.6rem;
    overflow: auto;
    padding-top: 2rem;
    &:before {
      content: 'Result';
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.4rem 0.6rem;
      font-size: 0.875rem;

      @media (max-width: 500px) {
        font-size: 0.8rem;
      }
    }
  }
  .live-error {
    font-size: 0.8rem;
    position: relative;
    padding: 0.6rem;
    overflow: auto;
    padding-top: 2rem;
    &:before {
      content: 'Error';
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.4rem 0.6rem;
      font-size: 0.875rem;
      color: #ee5252;

      @media (max-width: 500px) {
        font-size: 0.8rem;
      }
    }
  }

  .badge-btn-wrap {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }

  // Custom shell prompt
  .command-line-prompt > span:before {
    color: #999;
    content: ' ';
    display: block;
    padding-right: 0.8em;
  }

  /* Prompt for all users */
  .command-line-prompt > span[data-user]:before {
    content: '[' attr(data-user) '@' attr(data-host) '] $';
  }

  /* Prompt for root */
  .command-line-prompt > span[data-user='root']:before {
    content: '[' attr(data-user) '@' attr(data-host) '] #';
  }

  .command-line-prompt > span[data-prompt]:before {
    content: attr(data-prompt);
  }

  code[class*='language-'],
  pre[class*='language-'] {
    color: ${props => props.theme.code0};
    font-size: 0.8rem;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    background: transparent !important;

    line-height: 1.5;

    tab-size: 2;
    -moz-tab-size: 2;
    -o-tab-size: 2;

    hyphens: none;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
  }

  pre[class*='language-']::-moz-selection,
  pre[class*='language-'] ::-moz-selection,
  code[class*='language-']::-moz-selection,
  code[class*='language-'] ::-moz-selection {
    background: #073642;
  }

  pre[class*='language-']::selection,
  pre[class*='language-'] ::selection,
  code[class*='language-']::selection,
  code[class*='language-'] ::selection {
    background: #073642;
  }

  /* Code blocks */
  pre[class*='language-'] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    border-radius: 0.3em;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #656565;
  }

  .token.punctuation {
    color: ${props => props.theme.code3};
  }

  .namespace {
    opacity: 0.7;
  }

  .token.number {
    color: ${props => props.theme.code0};
  }

  .token.tag,
  .token.boolean,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: ${props => props.theme.code7};
  }

  .token.selector,
  .token.string,
  .token.char,
  .token.builtin,
  .token.url,
  .token.inserted,
  .token.attr-value {
    color: ${props => props.theme.code4};
  }

  .token.attr-name {
    color: ${props => props.theme.code5};
  }

  .token.class-name {
    color: ${props => props.theme.code6};
  }

  .token.entity {
    color: #657b83;
    background: #d3aa23;
  }

  .token.atrule,
  .token.keyword {
    color: ${props => props.theme.code1};
  }

  .token.function {
    color: ${props => props.theme.code2};
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: #2e94d3;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  // Preserve empty lines
  .token-line:not(:last-child) {
    min-height: 1.185rem;
  }

  @media (max-width: 500px) {
    // Decrease font size
    code[class*='language-'],
    pre[class*='language-'],
    .code-title-custom {
      font-size: 0.7rem;
      .token {
        font-size: 0.7rem;
      }
    }

    .live-error {
      font-size: 0.8rem;
    }

    // Decrease empty line height
    .token-line:not(:last-child) {
      min-height: 1.025rem;
    }
  }
`;

export const CodeContentCss = styled.div`
  position: relative;
  display: flex;
  font-size: 18px;
  outline-offset: 2px;
  overflow: auto;
  margin-left: -32px;
  margin-right: -32px;
  padding: 32px;
  background: ${props => props.theme.syntaxBg};
  max-height: 75vh;
  border-radius: 4px;

  pre {
    font-size: 17px !important;
  }

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const HighlightCss = styled.div`
  position: relative;
  padding-right: 10px;

  & pre[class*='language-'] {
    position: relative;
    background-color: transparent;
    margin: 0;
    padding: 0;
    overflow: initial;
  }

  /* Adjust the position of the line numbers */
  & pre[class*='language-'].line-numbers {
  }

  .badge-btn-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LanguageBadgeCss = styled.div`
  z-index: 10;
  position: absolute;
  top: 0px;
  right: 0;
  transform: translate(0, -100%);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: ${props => props.theme.syntaxBg};
  color: ${props => props.theme.gray700};
  padding: 7px 20px 4px;

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    right: 50px;
  }
`;

export const CopyWrapperCss = styled.div`
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 0;

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    right: 30px;
  }

  // Copy button
  .btn-copy {
    cursor: pointer;
    padding: 5px 6px;
    border-radius: 5px;
    background: transparent;
    font-weight: 600;
    color: ${props => props.theme.gray700};
    user-select: none;
    border: none;
    transition: none;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
    &:focus {
      outline-width: 1px;
    }
  }
`;
