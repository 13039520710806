import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { v4 as getID } from 'uuid';
import storage from 'local-storage-fallback';
import app from 'firebase/app';

const Context = createContext();
const { Provider } = Context;

axios.defaults.baseURL = process.env.GATSBY_APP_API;
axios.defaults.headers.common['Content-Type'] = 'application/json';

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLightTheme: false,
      toggleTheme: this.toggleTheme,
      getArticleInformation: this.getArticleInformation,
      likeArticle: this.likeArticle,

      // Subscribe
      alreadySubscribed: false,
      onSubscribe: this.onSubscribe,
      subscribed: false,
      subscribeLoading: false,
      subscribeError: null,
    };
  }

  componentDidMount() {
    // Get the default theme if is set
    const theme = storage.getItem('theme');
    const alreadySubscribed = storage.getItem('subscribed');

    // Get the user id if is saved else generate a new one
    let userId = storage.getItem('userId');
    if (!userId) {
      userId = getID();
      storage.setItem('userId', userId);
    }

    this.initFirebase(userId);

    this.setState({
      isLightTheme: theme && theme === 'light',
      userId,
      alreadySubscribed: !!alreadySubscribed,
    });
  }

  initFirebase = userId => {
    app.initializeApp({
      apiKey: 'AIzaSyBOt6QYa3IbzYF5n2NxJUNIUUB7RAMdsk0',
      authDomain: 'app-blog-razvan.firebaseapp.com',
      projectId: 'app-blog-razvan',
      storageBucket: 'app-blog-razvan.appspot.com',
      messagingSenderId: '856363419632',
      appId: '1:856363419632:web:89a40e717a00978fb9ff35',
      measurementId: 'G-H8RGFHZQNF',
    });
    import('firebase/analytics').then(() => {
      const analytics = app.analytics();
      analytics.setUserId(userId);

      this.setState({ analytics });
    });
  };

  toggleTheme = () => {
    this.setState({ isLightTheme: !this.state.isLightTheme });
    storage.setItem('theme', !this.state.isLightTheme ? 'light' : 'dark');
  };

  // ***** Blog info ***** //

  getArticleInformation = (slug, userId) => {
    const body = {
      slug,
      userId,
    };
    return axios
      .post('/articles/get-likes', body)
      .then(res => {
        return res.data.data;
      })
      .catch(err => {
        console.log('Error getting the likes', err);
      });
  };

  likeArticle = slug => {
    const { userId } = this.state;
    const body = {
      slug,
      userId,
    };
    return axios
      .post('/articles/set-likes', body)
      .then(res => {
        return res.data.data;
      })
      .catch(err => {
        console.log('Error getting the likes', err);
      });
  };

  onSubscribe = (name, email) => {
    this.setState({
      subscribeError: '',
      subscribeLoading: true,
    });
    const body = {
      name,
      email,
    };

    return axios
      .post('/subscribe', body)
      .then(res => {
        this.setState({
          subscribeError: '',
          subscribeLoading: false,
          subscribed: true,
        });
      })
      .catch(err => {
        this.setState({
          subscribeError: err.response.data.error,
          subscribeLoading: false,
        });
      });
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
export { Context, Provider };
